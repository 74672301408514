var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('el-timeline',{staticClass:"mt-3"},_vm._l((_vm.shipments),function(shipment,index){return _c('el-timeline-item',{key:index,attrs:{"type":shipment.status
        ? shipment.status.name == 'En proceo'
          ? 'info'
          : shipment.status.name == 'Completado'
          ? 'success'
          : shipment.status.name == 'Error'
          ? 'danger'
          : shipment.status.name == 'Detenido' ||
            shipment.status.name == 'Cancelado'
          ? 'warning'
          : 'other'
        : 'other'}},[_c('el-card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-10"},[_c('div',{staticClass:"row col-sm-12"},[_c('div',{staticClass:"col-sm-6"},[_c('strong',[_vm._v("Remitente: "+_vm._s(shipment.sender))])]),_c('div',{staticClass:"col-sm-6"},[(
                  !shipment.status ||
                    shipment.status.name == 'Pendiente' ||
                    shipment.status.name == 'En proceso'
                )?_c('small',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(shipment["max-size"] ? `${shipment["max-size"]} SMS / minuto` : "Sin límites")+" ")]):_vm._e()])]),_c('div',{staticClass:"row col-sm-12"},[_c('div',{staticClass:"col-sm-6"},[_c('small',{staticClass:"mr-5"},[_vm._v(" Enviados: "+_vm._s(_vm._f("number")(shipment["total-sent"]))+" / "+_vm._s(_vm._f("number")(shipment.receivers))+" SMS")])]),_c('div',{staticClass:"col-sm-6"},[_c('small',[_vm._v(" Inversión: "+_vm._s(_vm._f("number")(shipment["credit-spend"]))+" creditos")])])]),_c('div',{staticClass:"row col-sm-12"},[_c('div',{staticClass:"col-sm-6"},[_c('small',{staticClass:"mr-5"},[_vm._v(" Estatus: "+_vm._s(shipment.status ? shipment.status.name : "Pendiente")+" ")])]),_c('div',{staticClass:"col-sm-6"},[(shipment['last-shipment'])?_c('small',{staticClass:"mr-5"},[_vm._v(" Fecha de envío: "+_vm._s(_vm._f("moment")(shipment["last-shipment"] || "No disponible","calendar"))+" ")]):_c('small',{staticClass:"mr-5"},[_vm._v(" Envío programado: "+_vm._s(_vm._f("moment")(shipment["schedule-date"] || "No disponible","calendar"))+" ")])])])]),_c('div',{staticClass:"col-sm-2"},[(
              shipment.status && shipment.status.name == 'En proceso' && _vm.show
            )?_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"danger","size":"mini","plain":""},on:{"click":function($event){return _vm.stopShipment(shipment.id)}}},[_c('i',{staticClass:"far fa-stop-circle"}),_vm._v(" Detener ")]):_vm._e(),(
              shipment.status && shipment.status.name == 'Pendiente' && _vm.show
            )?_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"danger","size":"mini","plain":""},on:{"click":function($event){return _vm.stopShipment(shipment.id)}}},[_c('i',{staticClass:"fas fa-ban"}),_vm._v(" Cancelar ")]):_vm._e(),(
              shipment.status &&
                (shipment.status.name == 'Cancelado' ||
                  shipment.status.name == 'Detenido') &&
                _vm.show
            )?_c('el-button',{staticStyle:{"width":"100%"},attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){return _vm.restartShipment(shipment.id)}}},[_c('i',{staticClass:"fas fa-play"}),_vm._v(" Continuar ")]):_vm._e()],1)]),_c('hr'),_c('p',{staticClass:"px-4"},[_vm._v(_vm._s(shipment.content))])])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }